import $ from "jquery";
$(".filter-blk").on("click", function () {
  // alert("prope")
  $(".search-bar").toggleClass("bar-open");
});
$(document).ready(function () {

  // Menu code Starts
  // $(".menu-icon").click(function () {
  //   $("body").addClass("menu-open");
  //   $(".menu-mask").addClass("active");
  // });

  // $(".menu-close").click(function () {
  //   $("body").removeClass("menu-open");
  //   $(".menu-mask").removeClass("active");
  // });

  // $(".menu-mask, .nav-text,.dropdown-menu .dropdown-item li a").on(
  //   "click",
  //   function () {

  //     $("body").removeClass("menu-open");
  //     $(".menu-mask").removeClass("active");
  //   }
  // );

  // Menu code Ends

  /** search-bar **/
  /** Edit Profile **/
  /****/
  $(".profile-info .edit-btn").click(function () {
    $(".profile-info").addClass("hide");
    $(".profile-info").removeClass("show");
  });

  $(".profile-info .close-btn").click(function () {
    $(".profile-info").addClass("show");
    $(".profile-info").removeClass("hide");
  });

  /****/
  $(".certification-info .edit-btn").click(function () {
    $(".certification-info").addClass("hide");
    $(".certification-info").removeClass("show");
  });

  $(".certification-info .close-btn").click(function () {
    $(".certification-info").addClass("show");
    $(".certification-info").removeClass("hide");
  });

  /*****/
  $(".license-info .edit-btn").click(function () {
    $(".license-info").addClass("hide");
    $(".license-info").removeClass("show");
  });

  $(".license-info .close-btn").click(function () {
    $(".license-info").addClass("show");
    $(".license-info").removeClass("hide");
  });

  /****/
  $(".interests-info .edit-btn").click(function () {
    $(".interests-info").addClass("hide");
    $(".interests-info").removeClass("show");
  });

  $(".interests-info .close-btn").click(function () {
    $(".interests-info").addClass("show");
    $(".interests-info").removeClass("hide");
  });

  /****/
  $(".recruiter-info .edit-btn").click(function () {
    $(".recruiter-info").addClass("hide");
    $(".recruiter-info").removeClass("show");
  });

  $(".recruiter-info .close-btn").click(function () {
    $(".recruiter-info").addClass("show");
    $(".recruiter-info").removeClass("hide");
  });

  /****/
  $(".socila-info .edit-btn").click(function () {
    $(".socila-info").addClass("hide");
    $(".socila-info").removeClass("show");
  });

  $(".socila-info .close-btn").click(function () {
    $(".socila-info").addClass("show");
    $(".socila-info").removeClass("hide");
  });

  /****/
  $(".speciality-info .edit-btn").click(function () {
    $(".speciality-info").addClass("hide");
    $(".speciality-info").removeClass("show");
  });

  $(".speciality-info .close-btn").click(function () {
    $(".speciality-info").addClass("show");
    $(".speciality-info").removeClass("hide");
  });

  /****/
  $(".experience-info .edit-btn").click(function () {
    $(".experience-info").addClass("hide");
    $(".experience-info").removeClass("show");
  });

  $(".experience-info .close-btn").click(function () {
    $(".experience-info").addClass("show");
    $(".experience-info").removeClass("hide");
  });

  /****/
  $(".education-info .edit-btn").click(function () {
    $(".education-info").addClass("hide");
    $(".education-info").removeClass("show");
  });

  $(".education-info .close-btn").click(function () {
    $(".education-info").addClass("show");
    $(".education-info").removeClass("hide");
  });
}); // document ready close

// collapse
