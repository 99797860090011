export const GET_ERRORS = "GET_ERRORS";
export const GET_SUCCESS = "GET_SUCCESS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USER = "GET_USER";
export const GET_USER_INFO = "GET_USER_INFO";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_ERROR = "REGISTER_ERROR";

// Saved Jobs
export const SAVE_JOB = "SAVE_JOB";
export const SAVE_JOB_ERROR = "SAVE_JOB_ERROR";
export const SAVE_JOB_SUCCESS = "SAVE_JOB_SUCCESS";

